import { userAxios } from '../../index'

const medicationList = async perPage => {
  try {
    return await userAxios.get(`insurance/medicines/all/${perPage}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const medicationCreate = async data => {
  try {
    return await userAxios.post('insurance/medicines', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const medicationUpdate = async (id, data) => {
  try {
    return await userAxios.put(`insurance/medicines/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const medicationDelete = async id => {
  try {
    return await userAxios.delete(`insurance/medicines/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const medicationPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/medicines/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const medicationFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/medicines/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const medicationFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/medicines/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  medicationList,
  medicationCreate,
  medicationUpdate,
  medicationDelete,
  medicationPagination,
  medicationFilter,
  medicationFilterPagination,
}
